/*=========================================================================================
File Name: OurTeam.vue
Description: Our Team Page.
----------------------------------------------------------------------------------------
Item Name: Ekifa Vue || Business and Agency VueJs Template
Version: 1.0.0
Author: Deveable
Copyright 2021
==========================================================================================*/
<template>
<div>
  <!-- Breadcrumb Component -->
  <breadcrumb-area :breadcrumb="breadcrumb"/>
  <!-- Experts Component -->
  <expert-area/>
</div>
</template>

<script>
import BreadcrumbArea from "../../components/BreadcrumbArea";
import ExpertArea from "../../components/ExpertArea";
export default {
  name: "OurTeam",
  components: {ExpertArea, BreadcrumbArea},
  data() {
    return {
      breadcrumb: {
        page: "Our Team",
        pageTitle: "Our Team"
      }
    }
  }
}
</script>

<style scoped>

</style>